import ExpandAllAccordion from 'src/components/FullPatientData/PatientWidgetBody/ExpandAllAccordion/ExpandAllAccordion';
import { residentialAddress } from './computed/residentialAddress';
import { permanentAddress } from './computed/permanentAddress';
import { nextOfKinAddress } from './computed/nextOfKinAddress';

import { mapGetters, mapState } from 'vuex';
export default {
  components: {
    ExpandAllAccordion,
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    ...mapGetters(['applicant']),
    ...mapState({
      applicant: state => state.applicant,
      data: state => state.applicant.data,
      applicantHomeValidatedAddress: state =>
        state.applicant.homeValidatedAddress,
      billingMailingValidatedAddress: state =>
        state.applicant.data.mailingValidatedAddress,
      billingNextOfKinValidatedAddress: state =>
        state.applicant.data.nextOfKinValidatedAddress,
    }),
    accordions: {
      get: function() {
        //each item in the array will contian data for one accordian
        return {
          residentialAddr: residentialAddress.call(this),
          permanentAddr: permanentAddress.call(this),
          nextOfKinAddr: nextOfKinAddress.call(this),
        };
      },
    },
  },
};
