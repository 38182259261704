export function warnings() {
  return {
    label: 'Warnings',
    labelClass: 'warning',
    labelIcon: 'exclamation-triangle',
    isExpanded: false,
    isDisabled: this.demographic?.warnings ? false : true,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        id: 'warnings',
        fields: [
          {
            label: '',
            value: this.demographic?.warnings,
          },
        ],
      },
    },
  };
}
