export function mailingAddress() {
  return {
    label: 'Mailing Address',
    isExpanded: false,
    isDisabled: this.pcr.paddr ? false : true,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        id: 'mailingAddress',
        fields: [
          {
            label: 'Mailing Address',
            isAddress: true,
            address1: this.pcr.paddr,
            address2: this.pcr.paddr2,
            city: this.pcr.pcity,
            state: this.pcr.pstate,
            zip: this.pcr.pzip,
            validatedAddress: this.pcrPaddrValidatedAddress,
          },
        ],
      },
    },
  };
}
