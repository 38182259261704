import { dateStr } from 'src/utils/dateStr';
import { getAge } from './helpers/getAge';

export function demographics() {
  return {
    label: 'Demographics',
    isExpanded: false,
    isDisabled: this.pcr.firstname ? false : true,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        id: 'demographics',
        fields: [
          {
            label: 'First Name:',
            value: this.pcr.firstname,
          },
          {
            label: 'Last Name:',
            value: this.pcr.lastname,
          },
          {
            label: 'Race:',
            value: this.pcr.race,
          },
          {
            label: 'SSN:',
            value: this.pcr.ssn,
          },
          {
            label: 'DOB:',
            value: dateStr(this.pcr.dob),
          },
          {
            label: 'Age:',
            value: getAge(this.pcr.dob),
          },
          {
            label: 'Weight:',
            value: this.pcr.weight,
          },
          {
            label: 'Phone:',
            value: this.pcr.pphone,
          },
        ],
      },
    },
  };
}
