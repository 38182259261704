export function nextOfKinAddress() {
  return {
    label: 'Next of Kin Address',
    isExpanded: false,
    isDisabled: this.pcr.noK_addr ? false : true,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        id: 'nextOfKinAddress',
        fields: [
          {
            label: 'Next of Kin Address',
            isAddress: true,
            address1: this.pcr.noK_addr,
            address2: this.pcr.noK_addr2,
            city: this.pcr.noK_city,
            state: this.pcr.noK_state,
            zip: this.pcr.noK_zip,
            validatedAddress: this.pcrNokAddrValidatedAddress,
          },
        ],
      },
    },
  };
}
