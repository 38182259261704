import ExpandAllAccordion from 'src/components/FullPatientData/PatientWidgetBody/ExpandAllAccordion/ExpandAllAccordion';
import { mapState } from 'vuex';
import { demographics } from './computed/demographics';
import { homeAddress } from './computed/homeAddress';
import { mailingAddress } from './computed/mailingAddress';
import { nextOfKinAddress } from './computed/nextOfKinAddress';
import { nextOfKinDemographics } from './computed/nextOfKinDemographics';
export default {
  props: {
    pcr: Object,
    homeAddress: Object,
  },
  components: {
    ExpandAllAccordion,
  },
  computed: {
    ...mapState({
      pcrHomeValidatedAddress: state =>
        state.applicant.epcrJsonData?.haddrValidatedAddress,
      pcrPaddrValidatedAddress: state =>
        state.applicant.epcrJsonData?.paddrValidatedAddress,
      pcrNokAddrValidatedAddress: state =>
        state.applicant.epcrJsonData?.nokAddrValidatedAddress,
    }),

    accordions: {
      get: function() {
        //each item in the array will contian data for one accordian
        return {
          demographics: demographics.call(this),
          homeAddr: homeAddress.call(this),
          mailingAddr: mailingAddress.call(this),
          nextofKinDemographics: nextOfKinDemographics.call(this),
          nextOfKinAddr: nextOfKinAddress.call(this),
        };
      },
    },
  },
};
