export function redFlags() {
  return {
    label: 'Red Flags',
    labelClass: 'error',
    labelIcon: 'flag',
    isExpanded: false,
    isDisabled: this.demographic.redFlags ? false : true,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        id: 'redFlags',
        fields: [
          {
            label: '',
            value: this.demographic.redFlags,
          },
        ],
      },
    },
  };
}
