export default {
  props: {
    title: String,
    message: String,
  },
  computed: {
    loading: function() {
      return this.message === '';
    },
  },
  methods: {
    close() {
      this.message = '';
      this.title = '';
      this.$emit('close', null);
    },
    ok() {
      this.$emit('close', this.mappedPayor);
    },
  },
};