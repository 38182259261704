/**
 *
 * @param {*} relationNum
 * @returns String version of relation number provided, taken from: https://med.noridianmedicare.com/web/jea/topics/claim-submission/patient-relationship-codes
 */
export function getRelationStr(relationNum) {
  switch (relationNum) {
    case 1:
      return 'Spouse';
    case 4:
      return 'Grandfather or Grandmother';
    case 5:
      return 'Grandson or Grandaughter';
    case 7:
      return 'Nephew or Niece';
    case 10:
      return 'Foster Child';
    case 15:
      return 'Ward of the Court';
    case 17:
      return 'Stepson or Stepdaughter';
    case 18:
      return 'Self';
    case 19:
      return 'Child';
    case 20:
      return 'Employee';
    case 21:
      return 'Unknown';
    case 22:
      return 'Handicapped/Dependent';
    case 23:
      return 'Sponsored Dependent';
    case 24:
      return 'Dependent of Minor Dependent';
    case 29:
      return 'Significant Other';
    case 32:
      return 'Mother';
    case 33:
      return 'Father';
    case 36:
      return 'Emancipated Minor';
    case 39:
      return 'Organ Donor';
    case 40:
      return 'Cadaver Donor';
    case 41:
      return 'Injured Plaintiff';
    case 43:
      return 'Child Where Insured Has No Financial Responsibility';
    case 53:
      return 'Life Partner';
    case 'G8':
      return 'Other Relationship';
    default:
      return '';
  }
}
