export default {
  props: {
    originalField: String,
    compareField: String,
    showFlag: Boolean,
    isSSN: {
      type: Boolean,
      default: false,
    },
    isPhoneNumber: {
      type: Boolean,
      default: false,
    },
    isZip: {
      type: Boolean,
      default: false,
    },
  },
  //Non-numeric values have been stripped before passing over (to be accurately compared)
  //so we don't have to worry about that here
  methods: {
    getDisplaySSN(ssn) {
      var displaySSN = ssn;
      if (displaySSN.length == 9) {
        displaySSN =
          ssn.substr(0, 3) + '-' + ssn.substr(3, 2) + '-' + ssn.substr(5, 4);
      }
      return displaySSN;
    },
    getDisplayPhoneNumber(phoneNumber) {
      var displayNumber = phoneNumber;
      //Case of 123-4567
      if (phoneNumber.length === 7) {
        displayNumber = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3);
      }
      //Case of (123) 456-7890 or Case of +1 (123) 456-7890
      else if (phoneNumber.length === 10 || phoneNumber.length === 11) {
        var match = displayNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = match[1] ? '+1 ' : '';
          displayNumber = [
            intlCode,
            '(',
            match[2],
            ') ',
            match[3],
            '-',
            match[4],
          ].join('');
        }
      }

      return displayNumber;
    },
  },
};
