import ExpandAllAccordion from 'src/components/FullPatientData/PatientWidgetBody/ExpandAllAccordion/ExpandAllAccordion';
import { redFlags } from './computed/redFlags';
import { warnings } from './computed/warnings';

import { mapState } from 'vuex';
export default {
  components: {
    ExpandAllAccordion,
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    ...mapState({
      demographic: state => state.applicant.demographic.data,
    }),
    accordions: {
      get: function() {
        //each item in the array will contian data for one accordian
        return {
          redFlags: redFlags.call(this),
          warnings: warnings.call(this),
        };
      },
    },
  },
};
