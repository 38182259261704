export function residentialAddress() {
  return {
    label: 'Residential Address',
    isExpanded: false,
    isDisabled:
      this.applicant.homeAddress && this.applicant.homeAddress.address1
        ? false
        : true,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        id: 'residentialAddress',
        fields: [
          {
            label: 'Residential Address',
            isAddress: true,
            address1: this.applicant.homeAddress?.address1,
            address2: this.applicant.homeAddress?.address2,
            city: this.applicant.homeAddress?.city,
            state: this.applicant.homeAddress?.state,
            zip: this.applicant.homeAddress?.zip,
            validatedAddress: this.applicantHomeValidatedAddress,
          },
        ],
      },
    },
  };
}
