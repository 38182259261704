export function nextOfKinAddress() {
  return {
    label: 'Next of Kin Address',
    isExpanded: false,
    isDisabled:
      this.data.nextOfKinAddress && this.data.nextOfKinAddress.address1
        ? false
        : true,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        id: 'nextOfKinAddress',
        fields: [
          {
            label: 'Next of Kin Address',
            isAddress: true,
            address1: this.data.nextOfKinAddress?.address1,
            address2: this.data.nextOfKinAddress?.address2,
            city: this.data.nextOfKinAddress?.city,
            state: this.data.nextOfKinAddress?.state,
            zip: this.data.nextOfKinAddress?.zip,
            validatedAddress: this.billingNextOfKinValidatedAddress,
          },
        ],
      },
    },
  };
}
