import Accordion from 'src/components/FullPatientData/PatientWidgetBody/Accordion/Accordion';
import clone from 'clone';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    header: String,
  },
  components: {
    Accordion,
  },
  data() {
    return {
      accordions: {},
      expandAllState: false,
    };
  },
  created() {
    this.accordions = clone(this.data);
  },
  methods: {
    expandAll() {
      if (this.checkAllInSameState(false) && !this.expandAllState) {
        this.expandAllState = true;
      } else if (this.checkAllInSameState(true) && this.expandAllState) {
        this.expandAllState = false;
      }
      this.expandAllState = !this.expandAllState;
      for (const key in this.accordions) {
        if (!this.accordions[key].isDisabled) {
          this.accordions[key].isExpanded = this.expandAllState;
        }
      }
    },

    //Helper function to check if they are all already expanded (or collapsed)
    //(without having clicked the expandAll button)
    //collapsed = false is checking for all expanded
    //collapsed = true is checking for all collapsed
    checkAllInSameState(collapsed) {
      for (const key in this.accordions) {
        if (
          !this.accordions[key].isDisabled &&
          this.accordions[key].isExpanded === collapsed
        ) {
          return false;
        }
      }
      return true;
    },
    toggle(accordionIndex) {
      for (const [key] of Object.entries(this.accordions)) {
        if (key === accordionIndex) {
          this.accordions[accordionIndex].isExpanded = !this.accordions[
            accordionIndex
          ].isExpanded;
        }
      }
    },
  },
};
