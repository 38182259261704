import { getRelationStr } from './helpers/getRelationStr';

export function nextOfKinDemographics() {
  return {
    label: 'Next of Kin Demographics',
    isExpanded: false,
    isDisabled: this.pcr.noK_firstname ? false : true,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        id: 'nextOfKinDemographics',
        fields: [
          {
            label: 'First Name:',
            value: this.pcr.noK_firstname,
          },
          {
            label: 'Middle Name:',
            value: this.pcr.noK_middle_initial,
          },
          {
            label: 'Last Name:',
            value: this.pcr.noK_lastname,
          },
          {
            label: 'Relation:',
            value: getRelationStr(this.pcr.noK_relation),
          },
          {
            label: 'Phone:',
            value: this.pcr.noK_phone,
          },
        ],
      },
    },
  };
}
