import ProductLastRunBadge from 'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/ProductLastRunBadge/ProductLastRunBadge';
import Widget from 'src/components/FullPatientData/PatientWidgetBody/Widget/Widget';
import PatientInformationCardData from 'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/PatientInformationCardData/PatientInformationCardData';
import PatientInformationCardBottom from 'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/PatientInformationCardBottom/PatientInformationCardBottom';
import PCRInformation from 'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/PCRInformation/PCRInformation';
import BillingInformation from 'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/BillingInformation/BillingInformation';
import ErrorInformation from 'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/ErrorInformation/ErrorInformation';
import Demographic from 'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/Demographic/Demographic';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faCheckCircle,
  faExpand,
  faQuestionCircle,
  faCheck,
  faTimes,
  faFlag,
  faArrowUp,
  faArrowDown,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { mapGetters, mapState, mapActions } from 'vuex';
import { widgets } from 'src/constants/widgets';
import { permissionTypes } from 'src/constants/permissions';
import {
  GET_APPLICANT,
  GET_DEMOGRAPHIC,
  GET_GUARANTOR,
  SET_APPLICANT_HOME_VALIDATED_ADDRESS,
  SET_GUARANTOR_HOME_VALIDATED_ADDRESS,
  SET_PCR_HOME_VALIDATED_ADDRESS,
  SET_PCR_PADDR_VALIDATED_ADDRESS,
  SET_PCR_NOK_ADDR_VALIDATED_ADDRESS,
  SET_BILLING_MAILING_VALIDATED_ADDRESS,
  SET_BILLING_NEXTOFKIN_VALIDATED_ADDRESS,
} from 'src/store/actions/types';

// icon of the widget header
library.add(
  faUser,
  faCheckCircle,
  faExpand,
  faQuestionCircle,
  faCheck,
  faTimes,
  faFlag,
  faArrowUp,
  faArrowDown,
  faChevronDown,
  faChevronRight
);

export default {
  props: {
    loadedCallback: {
      type: Function,
      required: true,
    },
    dragStartHandler: {
      type: Function,
      required: true,
    },
  },
  components: {
    Widget,
    PatientInformationCardData,
    PatientInformationCardBottom,
    Demographic,
    PCRInformation,
    BillingInformation,
    ProductLastRunBadge,
    ErrorInformation,
  },
  methods: {
    ...mapActions([
      GET_APPLICANT,
      GET_DEMOGRAPHIC,
      GET_GUARANTOR,
      SET_APPLICANT_HOME_VALIDATED_ADDRESS,
      SET_GUARANTOR_HOME_VALIDATED_ADDRESS,
      SET_PCR_HOME_VALIDATED_ADDRESS,
      SET_PCR_PADDR_VALIDATED_ADDRESS,
      SET_PCR_NOK_ADDR_VALIDATED_ADDRESS,
      SET_BILLING_MAILING_VALIDATED_ADDRESS,
      SET_BILLING_NEXTOFKIN_VALIDATED_ADDRESS,
    ]),
  },
  computed: {
    ...mapGetters(['applicant', 'hasPermission']),
    ...mapState({
      applicantVisits: state => state.applicant.visits.data,
      demographic: state => state.applicant.demographic.data,
      guarantor: state => state.guarantor.data,
      guarantorDemo: state => state.guarantor.demographic.data,
      demographicRunTestStatus: state =>
        state.applicant.demographic.runTestStatus,
      loading: state => !state.applicant.visits.retrieved,
    }),
    widgetType() {
      return widgets.patientInformationCard.type;
    },
    // permission
    canView() {
      return this.hasPermission(permissionTypes.canViewApplicant);
    },
    displayBillingInformation() {
      return (
        this.applicant.data &&
        ((this.applicant.homeAddress && this.applicant.homeValidatedAddress) ||
          (this.applicant.data.mailingAddress &&
            this.applicant.data.mailingValidatedAddress) ||
          (this.applicant.data.nextOfKinAddress &&
            this.applicant.data.nextOfKinValidatedAddress))
      );
    },
    displayPcrInformation() {
      return (
        this.applicant.epcrJsonData &&
        ((this.applicant.epcrJsonData.haddr &&
          this.applicant.epcrJsonData.haddrValidatedAddress) ||
          (this.applicant.epcrJsonData.paddr &&
            this.applicant.epcrJsonData.paddrValidatedAddress) ||
          (this.applicant.epcrJsonData.noK_addr &&
            this.applicant.epcrJsonData.nokAddrValidatedAddress))
      );
    },
    displayErrorInformation() {
      return (
        this.demographic &&
        (this.demographic.redFlags || this.demographic.warnings)
      );
    },
  },
  watch: {
    // TODO: refactor this into the widget component,
    // when ALL widgets actually use that component, to be DRY
    loading: {
      immediate: true,
      handler(current, previous) {
        // make sure the DOM is updated before dispatching this action
        // because we need the widget to have its full dimensions after
        // loading the data and rendering them...
        this.$nextTick(function() {
          if (current !== previous) {
            this.loadedCallback(!current);
          }
        });
      },
    },
  },
  created() {
    if (this.applicant.guarantorId) {
      this[GET_GUARANTOR](this.applicant.guarantorId);
    }
    this[SET_APPLICANT_HOME_VALIDATED_ADDRESS]();
    this[SET_GUARANTOR_HOME_VALIDATED_ADDRESS]();
    this[SET_PCR_HOME_VALIDATED_ADDRESS]();
    this[SET_PCR_PADDR_VALIDATED_ADDRESS]();
    this[SET_PCR_NOK_ADDR_VALIDATED_ADDRESS]();
    this[SET_BILLING_MAILING_VALIDATED_ADDRESS]();
    this[SET_BILLING_NEXTOFKIN_VALIDATED_ADDRESS]();
  },
  beforeDestroy() {
    this.loadedCallback(false);
  },
};
