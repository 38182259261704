import mismatchField from 'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/MismatchField/MismatchField';
import DisplayIcon from 'src/components/FullPatientData/PatientWidgetBody/InsuranceWidgetNew/DiscoveryTable/DisplayIcon/DisplayIcon';
import { permissionTypes } from 'src/constants/permissions';
import { mapGetters, mapActions, mapState } from 'vuex';
import {
  GET_APPLICANT,
  GET_DEMOGRAPHIC,
  GET_GUARANTOR,
  FLAG_DEMOGRAPHICS_INCORRECT,
  FLAG_DEMOGRAPHICS_INCORRECT_GUARANTOR,
  RUN_DEMOGRAPHICS_TEST,
} from 'src/store/actions/types';

const highScore = 0.45;
export default {
  props: {
    demographic: Object,
    applicant: Object,
    forGuarantor: Boolean,
  },
  components: {
    'mismatch-field': mismatchField,
    DisplayIcon,
  },
  data() {
    return {
      checkedNames: [],
      polling: null,
      //zip4 is tricky so creating objects here to use
      demographicZip: '',
      applicantZip: '',
      demographicZip4: '',
      applicantZip4: '',
    };
  },

  computed: {
    ...mapGetters(['isTriTech', 'hasPermission']),
    ...mapState({
      demographicRunTestStatus: state =>
        state.applicant.demographic.runTestStatus,
    }),
    canFlagAsIncorrect() {
      return this.hasPermission(permissionTypes.canFlagAsIncorrect);
    },
    canUpdateRnFromDemographics() {
      return this.hasPermission(permissionTypes.canUpdateRnFromDemographics);
    },
    canRunDemographics() {
      return this.hasPermission(permissionTypes.canRunDemographics);
    },
    canViewDemographics() {
      return this.hasPermission(permissionTypes.viewDemographics);
    },
  },
  methods: {
    ...mapActions([
      GET_APPLICANT,
      GET_DEMOGRAPHIC,
      GET_GUARANTOR,
      FLAG_DEMOGRAPHICS_INCORRECT,
      RUN_DEMOGRAPHICS_TEST,
      FLAG_DEMOGRAPHICS_INCORRECT_GUARANTOR,
    ]),
    isConfidenceHigh() {
      return (
        this.demographic.confidenceScore &&
        this.demographic.confidenceScore > highScore
      );
    },

    stripNonNumericValues(value) {
      return value ? value.replace(/\D/g, '') : '';
    },
    trimString(strToTrim) {
      return strToTrim ? strToTrim.trim() : '';
    },
    async flagAsIncorrect(evt) {
      var checked = false;
      if (evt && evt.target && evt.target.checked) {
        checked = true;
      }
      if (this.forGuarantor) {
        this[FLAG_DEMOGRAPHICS_INCORRECT_GUARANTOR]({
          demographicId: this.demographic.demographicId,
          checked: checked,
        });
        this[GET_GUARANTOR](this.applicant.applicantId);
      } else {
        this[FLAG_DEMOGRAPHICS_INCORRECT]({
          demographicId: this.demographic.demographicId,
          checked: checked,
        });
      }
    },

    async runDemographicTest() {
      this[RUN_DEMOGRAPHICS_TEST]();
    },

    async updateRN() {
      var json = {};
      for (var i = 0; i < this.checkedNames.length; i++) {
        var updateType = this.checkedNames[i];
        if (updateType === 'firstname') {
          json.firstName = this.demographic.firstname;
        }
        if (updateType === 'lastname') {
          json.lastName = this.demographic.lastname;
        }
        if (updateType === 'ssn') {
          json.ssn = this.demographic.ssn;
        }
        if (updateType === 'phone') {
          json.phoneNumber = this.demographic.phoneNumber;
        }
        if (updateType === 'dob') {
          json.dob = this.demographic.dob;
        }
        if (updateType === 'address') {
          json.address1 = this.demographic.address.address1;
          json.address2 = this.demographic.address.address2;
          json.city = this.demographic.address.city;
          json.state = this.demographic.address.state;
          json.zip = this.demographic.address.zip;
          json.zip4 = this.demographic.address.zip4;
        }
      }
      try {
        // // eslint-disable-next-line
        // await getCefSharpBoundAsync2()?.showMessage(json);
        // eslint-disable-next-line
        await CefSharp.BindObjectAsync('cefSharpBoundAsync2', 'bound');
        // eslint-disable-next-line
        cefSharpBoundAsync2.showMessage(json);
      } catch (error) {
        this.displayErrorToast(
          'CefSharpLaunch Error: (most likely not running withn RN->PL app)' +
            error
        );
      }
    },
    setZipCodes(appZip, demZip, appZip4, demZip4) {
      //If the zips were given to us as we expected
      //set them appropriatly
      if (appZip && appZip.length === 5) {
        this.applicantZip = appZip;
      }
      if (demZip && demZip.length === 5) {
        this.demographicZip = demZip;
      }
      if (appZip4 && appZip4.length === 4) {
        this.applicantZip4 = appZip4;
      }
      if (demZip4 && demZip4.length === 4) {
        this.demographicZip4 = demZip4;
      }
      //if they were not given as expected, try to parse
      //what was given
      if (!this.applicantZip) {
        var fullAppZip = this.parseFullZip(appZip);
        this.applicantZip = fullAppZip.zip;
        if (!this.applicantZip4) {
          this.applicantZip4 = fullAppZip.zip4;
        }
      }
      if (!this.demographicZip) {
        var fullDemZip = this.parseFullZip(demZip);
        this.demographicZip = fullDemZip.zip;
        if (!this.demographicZip4) {
          this.demographicZip4 = fullDemZip.zip4;
        }
      }
    },
    parseFullZip(zipCode) {
      var cleanZip = this.stripNonNumericValues(zipCode);
      var zipStr = '';
      var zip4Str = '';

      if (cleanZip && cleanZip.length > 5) {
        //Take the first 5 digits and make it the zip
        zipStr = cleanZip.substr(0, 5);
        //take the rest and shove it into zip4
        zip4Str = cleanZip.substr(5);
      }

      return {
        zip: zipStr,
        zip4: zip4Str,
      };
    },
  },
  watch: {
    demographicRunTestStatus: {
      immediate: true,
      handler(current, previous) {
        if (current !== previous && current === 'success') {
          this[GET_DEMOGRAPHIC]();
        }
      },
    },
  },
  created() {
    this.setZipCodes(
      this.trimString(this.applicant?.homeAddress?.zip),
      this.trimString(this.demographic?.address?.zip),
      this.trimString(this.applicant?.homeAddress?.zip4),
      this.trimString(this.demographic?.address?.zip4)
    );
  },
};
