export function homeAddress() {
  return {
    label: 'Home Address',
    isExpanded: false,
    isDisabled: this.pcr.haddr ? false : true,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        id: 'homeAddress',
        fields: [
          {
            label: 'Home Address',
            isAddress: true,
            address1: this.pcr.haddr,
            address2: this.pcr.haddr2,
            city: this.pcr.hcity,
            state: this.pcr.hstate,
            zip: this.pcr.hzip,
            validatedAddress: this.pcrHomeValidatedAddress,
          },
        ],
      },
    },
  };
}
