import { zeroPad } from './zeroPad';
/**
 * Takes a string and returns a formatted date string
 * @param {object}
 * @returns {string}
 */
export const dateStr = dateVal => {
  var dateString = '';
  if (dateVal) {
    if (dateVal.indexOf('T') === -1) {
      dateVal = dateVal + 'T00:00:00.000';
    }
    var date = new Date(dateVal);
    dateString =
      zeroPad(date.getMonth() + 1) +
      '/' +
      zeroPad(date.getDate()) +
      '/' +
      date.getFullYear();
  }
  return dateString;
};
