import messageModal from 'src/components/MessageModal/MessageModal';
import AddressControl from 'src/components/FullPatientData/PatientWidgetBody/AddressControlUpdated/AddressControlUpdated';
import { mapState } from 'vuex';
export default {
  props: {
    homeAddress: Object,
    applicant: Object,
    forGuarantor: Boolean,
  },
  data() {
    return {
      isModalVisible: false,
      message: '',
      title: 'Validation Message',
    };
  },
  components: {
    'address-control': AddressControl,
    'message-modal': messageModal,
  },
  methods: {
    getAge(dob) {
      var ageDifMs = Date.now() - new Date(dob).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
  },

  computed: {
    ...mapState({
      applicantHomeValidatedAddress: state =>
        state.applicant.homeValidatedAddress,
      guarantorHomeValidatedAddress: state =>
        state.guarantor.data.homeValidatedAddress,
    }),
  },
};
