/**
 * Takes a number value and if its a single digit pads it with a zero
 * @param {*} numVal
 * @returns
 */
export const zeroPad = numVal => {
  if (numVal < 10) {
    return '0' + numVal;
  }
  return numVal;
};
