import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    address1: String,
    address2: String,
    city: String,
    state: String,
    zip: String,
    zip4: String,
    validatedAddress: Object,
    label: {
      type: String,
      default: 'Address:',
    },
  },
  data() {
    return {
      isModalVisible: false,
      message: '',
      title: 'Validation Message',
      uniqueId: uuidv4(),
      infoWindow: {
        open: false,
      },
    };
  },
  components: {},
  computed: {
    htmlLiink() {
      return (
        'https://www.google.com/maps/place/' +
        this.address1 +
        '+' +
        this.city +
        '+' +
        this.state +
        '+' +
        this.zip +
        '/@' +
        this.validatedAddress.latitude +
        ',' +
        this.validatedAddress.longitude +
        '&amp;z=16'
      );
    },
  },
};
