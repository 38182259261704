export default {
  props: {
    applicantId: Number,
    visit: Object,
  },
  methods: {
    getTripNumber(tdate, runNumber) {
      if (tdate && runNumber) {
        return tdate.substring(2, 4) + '-' + runNumber;
      }
    },
  },
};

