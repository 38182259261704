import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/pro-light-svg-icons';

library.add(faChevronRight);
library.add(faChevronDown);

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bodyComponent: {
      type: Function,
      required: true,
    },
    bodyProps: {
      type: Object,
      required: true,
    },
    toggle: {
      type: Function,
      required: true,
    },
  },
};
