export function permanentAddress() {
  return {
    label: 'Permanent Address',
    isExpanded: false,
    isDisabled:
      this.data.mailingAddress && this.data.mailingAddress.address1
        ? false
        : true,
    bodyComponent: () => ({
      component: import(
        'src/components/FullPatientData/PatientWidgetBody/PatientInformationCard/AccordionBody/AccordionBody'
      ),
    }),
    bodyProps: {
      data: {
        id: 'permanentAddress',
        fields: [
          {
            label: 'Permanent Address',
            isAddress: true,
            address1: this.data.mailingAddress?.address1,
            address2: this.data.mailingAddress?.address2,
            city: this.data.mailingAddress?.city,
            state: this.data.mailingAddress?.state,
            zip: this.data.mailingAddress?.zip,
            validatedAddress: this.billingMailingValidatedAddress,
          },
        ],
      },
    },
  };
}
